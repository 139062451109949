import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import { black1, white } from "../styles/Colors";
import { Headline80 } from "../styles/Headings";
import OutlinedText from "../styles/OutlinedText";
import { mediaMedium } from "../styles/variables";
import LinesFromBottom from "../LinesFromBottom";

const Wrapper = styled.div`
  background: ${black1};
  overflow: ${(props) => props?.theme?.overflow ?? 'hidden'};
  margin-top: ${(props) => props?.theme?.marginTopMobile ?? '-60px'};
  padding-top: 80px;
  
  
  @media (${mediaMedium}) {
    margin-top: ${(props) => props?.theme?.marginTop ?? 0}px;
    padding-top: ${(props) => props?.theme?.paddingTop ?? 0}px;
  }
`;

const Grid = styled.div`
  padding: 88px 0;

  @media (${mediaMedium}) {
    padding: 144px 0 146px;
    display: grid;
    grid-template-columns: calc(100% * 6 / 12);
    grid-column-gap: 24px;
  }
`;

const Title = styled(Headline80)`
  color: ${white};
  margin-bottom: 58px;

  @media (${mediaMedium}) {
    margin-bottom: 65px;
  }
`;

const Decoration = styled.div`
  display: none;

  @media (${mediaMedium}) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    right: calc(100% * 1.5 / 12);
    bottom: -8%;
  }

  @media (min-width: 890px) {
    bottom: 4%;
  }

  @media (min-width: 950px) {
    bottom: 18%;
  }

  @media (min-width: 1050px) {
    bottom: 400px;
  }
`;

const ContainerRelative = styled(Container)`
  position: relative;
`;

const EntrepreneurChallenge = ({children, title1, title2, theme}) => {
  return (
    <Wrapper theme={theme}>
      <ContainerRelative>
        <Decoration>
          <LinesFromBottom />
        </Decoration>
        <Grid>
          <div>
            <Title as={"div"}>
              {title1}{" "}
              <OutlinedText
                fill={"transparent"}
                stroke={white}
                component={Headline80}
                as={"span"}
              >
                {title2}
              </OutlinedText>
            </Title>
            {children}
          </div>
        </Grid>
      </ContainerRelative>
    </Wrapper>
  );
};

export default EntrepreneurChallenge;
