import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/BrandsStackAgencyStatic";

const AsyncSection = loadable(() =>
  import("../../components/sections/BrandsStackAgencyDefer")
);

const StackMedia = () => (
  <Layout>
    <Seo
      title="StackAgency for Brands"
      description="Connecting brands with highly engaged audiences through sponsored content"
    />

    <LazyLoadSection static={<StaticSection />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default StackMedia;
