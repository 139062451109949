import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {  white } from '../styles/Colors'
import { SlideInBottom } from '../styles/animations'
import OutlinedText from '../styles/OutlinedText'
import { Headline80 } from '../styles/Headings'
import HomeLanding from '../HeroLanding'
import EntrepreneurChallenge from '../publishers/EntrepreneurChallenge'
import { Body24 } from '../styles/BodyTypes'

const BrandsStackAgencyStatic = () => {
  const images = useStaticQuery(graphql`
    query {
      landingDesktop: file(relativePath: { eq: "stackagency/Ipad_12_NYP_content.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      landingMedia: file(relativePath: { eq: "stackagency/Ipad_12_NYP_content.png" }) {
        childImageSharp {
          fluid(maxWidth: 911, maxHeight: 1012, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      landingMedia: file(relativePath: { eq: "stackagency/Ipad_12_NYP_content.png" }) {
        childImageSharp {
          fluid(maxWidth: 911, maxHeight: 1012, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <div style={{overflow: 'hidden'}}>
      <HomeLanding
        section="STACKAGENCY FOR BRANDS"
        image={images.landingDesktop}
        link={{
          attrs: {
            href: "mailto:agency@stackcommerce.com?subject=STACKAGENCY%20For%20Publishers",
            as: 'a',
          },
          label: 'Get Started'
        }}
        cleanTitle
        imageMobile={images.landingMedia}
        bottomMargins="-35%"
        theme={{
          wrapperOverflow      : 'visible',
          titleWidth           : '820px',
          keyFrames            : SlideInBottom,
          mediaTransform       : 'translateX(-12px)',
          imageRatio           : '110%',
          imageWidth           : '140%',
          imageMaxWidth        : '140vw',
          topPadding           : '98px',
          bottomPadding        : '240px',
          imageTranslate       : 'translate(calc(100% * 3.9 / 12), calc(100% * 2.8 / 12))',
          imageMarginTop       : 0,
          mediaImgMargins      : '0 auto',
          bottomSize           : '0',
          mediaImgWidth        : '90%',
          bottomSizeBottomMedia: '0',
          gatsbyWidth          : '880px',
        }}
      >
        <Headline80>
          CONNECTING BRANDS WITH HIGHLY ENGAGED AUDIENCES
          <OutlinedText component={Headline80} as="span">
            THROUGH SPONSORED CONTENT
          </OutlinedText>
        </Headline80>
      </HomeLanding>


      <EntrepreneurChallenge
        title1="WHY"
        title2="STACKAGENCY?"
        theme={{
          marginTopMobile: '110px'
        }}
      >
        <Body24 theme={{ color: white, margins: '0 0 4.2rem' }}>
          StackAgency has partnered with the world’s leading publications to
          be
          your one-stop shop for performance marketing and commerce
          content
          opportunities. We offer guaranteed evergreen and promotional coverage
          on major mass-media publications, brokered on a fixed-fee basis.
        </Body24>
        <Body24 theme={{ color: white, margins: '4.2rem 0 0' }}>
          You’ll be able to scale exclusive, custom campaigns and boost reach
          with efficiency and very little lift, thanks to our turnkey solution
          that includes support from concept to completion. Our experts have
          decades of experience in performance-focused content marketing and are
          supported by StackCommerce’s industry-leading team. With partner
          relationships built on results, our execution and accountability is
          unmatched.
        </Body24>
      </EntrepreneurChallenge>

    </div>
  )
}

export default BrandsStackAgencyStatic
