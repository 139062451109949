import React, { useState } from "react";
import styled, { css } from "styled-components";
import { black1, white } from "./styles/Colors";
import { mediaDesktop, mediaMedium } from "./styles/variables";
import MainHeroTitle from "./MainHeroTitle";
import Container from "./styles/Container";
import { Eyebrows20 } from "./styles/Eyebrows";
import Image from "./Image";

const ContainerRelative = styled(Container)`
  position: relative;
  padding-top: 43px;

  @media (${mediaMedium}) {
    padding-top: ${(props) => props.theme.topPadding ?? "38px"};
    padding-bottom: ${(props) => props.theme.bottomPadding ?? "240px"};
  }

  @media (${mediaDesktop}) {
    padding-top: ${(props) => props.theme.topLargePadding ?? "38px"};
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  max-width: 100vw;
  background-color: ${white};
  overflow: ${(props) => props.theme.wrapperOverflow ?? "hidden"};
  padding-bottom: ${(props) => props.theme.bottomPaddingMedia ?? "0px"};

  @media (${mediaMedium}) {
    padding-bottom: 0;
  }
`;

const Display = styled.div`
  width: ${(props) => props.theme.imageWidth ?? "100vw"};
  max-width: ${(props) => props.theme.imageMaxWidth ?? "100vw"};
  transform: ${(props) => props.theme.mediaTransform ?? "translateX(-20px)"};
  margin-top: ${(props) => props.theme.imageMarginTop ?? "64px"};
  ${(props) => (props.hasMobileImage ? "display: none;" : "")};

  .gatsby-image-wrapper {
    opacity: ${(props) => (props.active ? 1 : 0)};
    ${(props) => props.theme.animationPre ?? ""}
    animation: ${(props) =>
      props.theme.keyFrames && props.active
        ? css`
            ${props.theme.keyFrames}
          `
        : "none"}
      0.9s ease both 0.5s;
  }

  @media (${mediaMedium}) {
    ${(props) => (props.theme.styles ? props.theme.styles : "")};
    ${(props) => (props.hasMobileImage ? "display: block;" : "")};
    position: absolute;
    right: 0;
    border: 0;
    bottom: ${(props) => props.theme.imageBottom ?? "0"};
    transform: ${(props) =>
      props.theme.imageTranslate ??
      "translate(calc(100% * 4 / 12), calc(100% * 2 / 12))"};
    z-index: 1;
    width: ${(props) => props.theme.imageSize ?? "calc(100% * 10 / 12)"};

    .gatsby-image-wrapper {
      margin-right: 0;
      margin-left: auto;

      ${(props) =>
        props.theme.gatsbyWidth
          ? `max-width: ${props.theme.gatsbyWidth} !important; max-height: none !important`
          : ""};
    }
  }
`;

const DesktopImage = styled(Image)`
  display: block;
  width: 100%;
`;

const Bottom = styled.div`
  background-color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop ?? "0"};
  position: relative;
  z-index: 1;

  @media (min-width: 720px) {
    margin-top: ${(props) => `calc(${props.marginTop}/1.5)` ?? "0"};
  }

  @media (${mediaMedium}) {
    z-index: initial;
    margin: 0;
  }
`;

const Space = styled.div`
  padding-top: ${(props) => props.theme.bottomSizeMedia ?? "0"};
  padding-bottom: ${(props) => props.theme.bottomSizeBottomMedia ?? "93px"};

  @media (${mediaMedium}) {
    display: block;
    width: 100%;
    padding-top: ${(props) => props.theme.bottomSize ?? "104px"};
  }
`;

const SubTitle = styled(Eyebrows20)`
  margin: 0 0 28px;
`;

const ImageMedia = styled(Image)`
  pointer-events: none;
  max-width: ${(props) => props.theme.mediaImgWidth ?? "100%"} !important;
  max-height: ${(props) => props.theme.mediaImgHeight ?? "100%"} !important;
  width: 100%;
  margin: ${(props) => props.theme.mediaImgMargins ?? "40px 0 0"};
  ${(props) => props.theme.mediaCss ?? ""};
  z-index: 2;
  transform: ${(props) => props.theme.mediaTransform ?? "none"};

  opacity: ${(props) => (props.active ? 1 : 0)};
  ${(props) => props.theme.animationPre ?? ""}
  animation: ${(props) =>
    props.theme.keyFrames && props.active
      ? css`
          ${props.theme.keyFrames}
        `
      : "none"}
      0.9s ease both 0.5s;

  @media (${mediaMedium}) {
    max-width: 90% !important;
    max-height: none !important;
    display: none;
  }
`;

const Blur = styled.div`
  z-index: 2;
  width: 100%;
  height: ${(props) => props.theme.blurheight ?? 30}px;
  position: absolute;
  bottom: ${(props) => props.theme.blurPosition ?? 0};
  right: -25%;
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 0.95) 0%,
    rgba(245, 245, 245, 0.9) 20%,
    rgba(245, 245, 245, 0) 100%
  );

  @media (${mediaMedium}) {
    height: ${(props) => props.theme.blurHeightLarge ?? 110}px;
  }
`;

const HomeLanding = ({
  animationComponent,
  link,
  image,
  imageMobile,
  theme,
  cleanTitle,
  children,
  section,
  bottomMargins,
  noBottom,
  blurImage
}) => {
  const hasMobileImage = !!imageMobile;

  const [active, setActive] = useState(false);

  const handleLoad = () => {
    setActive(true);
  };

  return (
    <Wrapper theme={theme}>
      <ContainerRelative theme={theme}>
        {section && <SubTitle as="span">{section}</SubTitle>}
        <MainHeroTitle
          cleanTitle={cleanTitle}
          theme={theme}
          link={link.attrs ?? null}
          label={link.label}
        >
          {children}
        </MainHeroTitle>
        {!animationComponent && image && (
          <Display
            theme={theme}
            active={active}
            hasMobileImage={hasMobileImage}
          >
            <DesktopImage
              onLoad={handleLoad}
              theme={theme}
              fluid={image.childImageSharp.fluid}
            />
          </Display>
        )}

        {animationComponent && animationComponent}
      </ContainerRelative>

      {!animationComponent && imageMobile && (
        <ImageMedia
          active={active}
          onLoad={handleLoad}
          theme={theme}
          fluid={image.childImageSharp.fluid}
        />
      )}

      {!noBottom && (
        <Bottom
          marginTop={bottomMargins}
          color={theme && theme.bottom ? theme.bottom : black1}
        >
          <Container>
            <Space theme={theme} />
          </Container>
        </Bottom>
      )}
      {blurImage && (
        <Container style={{ position: "relative" }}>
          <Blur theme={theme} />
        </Container>
      )}
    </Wrapper>
  );
};

export default HomeLanding;
