import styled, { keyframes, css } from "styled-components";

export const FadeSlideInTopRight = keyframes`
  0% {
    transform: translateY(-20%) translateX(20%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
`;

export const SlideInRight = keyframes`
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const FadeSlideInRight = keyframes`
  0% {
    transform: translateX(30%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const SlideInLeft = keyframes`
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const SlideInBottom = keyframes`
  0% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const SlideInTop = keyframes`
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const FlyBy = keyframes`
  0% {
    transform: translateY(20%) translateX(-20%);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
`;

export const FadeInFrames = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const WobbleFrames = keyframes`
  0% {
    -webkit-transform: none;
    transform: none
  }
  15% {
    transform: translate3d(0, -15%, 0);
  }
  30% {
    transform: translate3d(0, 10%, 0);
  }
  45% {
    transform: translate3d(0, -15%, 0);
  }
  60% {
    transform: translate3d(0, 0%, 0);
  }
  75% {
    transform: translate3d(0, -15%, 0);
  }
  100% {
    transform: none
  }
`;

export const FadeInBottomFrames = keyframes`
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const MoveInBottom = styled.div`
  transition: ease 0.8s;
  transition-property: transform;
  transform: translateY(
    ${(props) => (props.active ? 0 : props.translateFrom ?? 20)}%
  );
  transition-delay: ${(props) => (props.active ? 0 : 1)}s;
`;

export const FadeInBottom = styled.div`
  transform: translateY(20%);
  opacity: 0;
  animation: ${(props) =>
    props.active
      ? css`
          ${FadeInBottomFrames} 0.8s ease forwards 0.1s
        `
      : "none"};
`;

export const FadeInRight = styled.div`
  transition: ease 0.8s;
  transition-property: transform;
  transition-delay: ${(props) => (props.active ? 0 : 1)}s;
  transform: translateX(${(props) => (props.active ? 0 : 20)}%);
`;

export const FadeInLeft = styled.div`
  transition: ease 0.8s;
  transition-property: transform, opacity;
  transition-delay: ${(props) => (props.active ? 0 : 1)}s;
  transform: translateX(${(props) => (props.active ? 0 : -70)}%);
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

export const FadeIn = styled.div`
  transition: ease 0.8s;
  transition-property: opacity;
  transition-delay: ${(props) => (props.active ? 0 : 1)}s;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

export const FadeInSmall = styled(FadeInBottom)`
  transform: translateY(${(props) => (props.active ? 0 : 20)}%);
`;

export const FadeInRightSmall = styled(FadeInRight)`
  transform: translateX(${(props) => (props.active ? 0 : 50)}%);
`;

export const FadeInLeftSmall = styled.div`
  transition: ease 0.8s;
  transition-property: transform, opacity;
  transition-delay: ${(props) => (props.active ? 0 : 1)}s;
  transform: translateX(${(props) => (props.active ? 0 : -20)}%);
  opacity: ${(props) => (props.active ? 1 : 0)};
`;